<template>
  <v-form>
    <v-text-field v-model="firstname" dense label="First Name" outlined placeholder="First Name"></v-text-field>

    <v-text-field v-model="email" dense label="Email" outlined placeholder="Email" type="email"></v-text-field>

    <v-text-field v-model="mobile" dense label="Mobile" outlined placeholder="Number" type="number"></v-text-field>

    <v-text-field
      v-model="password"
      dense
      label="Password"
      outlined
      placeholder="password"
      type="password"
    ></v-text-field>

    <v-combobox v-model="password" dense label="ComboBox" outlined placeholder="combobox"></v-combobox>

    <v-checkbox v-model="checkbox" class="mt-0" label="Remember me"></v-checkbox>

    <v-btn color="primary">
      Submit
    </v-btn>

    <v-btn class="mx-2" outlined type="reset">
      Reset
    </v-btn>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const firstname = ref('')
    const email = ref('')
    const mobile = ref()
    const password = ref()
    const checkbox = ref(false)

    return {
      firstname,
      email,
      mobile,
      password,
      checkbox,
    }
  },
}
</script>
