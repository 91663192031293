<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12" md="3">
        <label for="firstname">First Name</label>
      </v-col>

      <v-col cols="12" md="9">
        <v-text-field
          id="firstname"
          v-model="firstname"
          dense
          hide-details
          outlined
          placeholder="First Name"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <label for="email">Email</label>
      </v-col>

      <v-col cols="12" md="9">
        <v-text-field id="email" v-model="email" dense hide-details outlined placeholder="Email"></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <label for="mobile">Mobile</label>
      </v-col>

      <v-col cols="12" md="9">
        <v-text-field
          id="mobile"
          v-model="mobile"
          dense
          hide-details
          outlined
          placeholder="Number"
          type="number"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <label for="password">Password</label>
      </v-col>

      <v-col cols="12" md="9">
        <v-text-field
          id="password"
          v-model="password"
          dense
          hide-details
          outlined
          placeholder="Password"
          type="password"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <label for="Date">Date Picker</label>
      </v-col>

      <v-col cols="12" md="9">
        <DatePicker placeholder="Date Picker" />
      </v-col>

      <v-col cols="12" md="3">
        <label for="Date">ComboBox</label>
      </v-col>

      <v-col cols="12" md="9">
        <v-combobox v-model="comboBox" dense hide-details multiple outlined placeholder="ComboBox" small-chips />
      </v-col>

      <v-col cols="12" md="3">
        <label for="Date">Autocomplete</label>
      </v-col>

      <v-col cols="12" md="9">
        <v-autocomplete
          v-model="comboBox"
          dense
          hide-details
          multiple
          outlined
          placeholder="Autocomplete"
          small-chips
        />
      </v-col>

      <v-col cols="12" offset-md="3">
        <v-checkbox v-model="checkbox" class="mt-0" hide-details label="Remember me"></v-checkbox>
      </v-col>

      <v-col cols="12" offset-md="3">
        <v-btn color="primary">
          Submit
        </v-btn>
        <v-btn class="mx-2" outlined type="reset">
          Reset
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'
import DatePicker from '@/components/DatePicker'

export default {
  components: { DatePicker },
  setup() {
    const firstname = ref('')
    const email = ref('')
    const mobile = ref()
    const password = ref()
    const checkbox = ref(false)
    const comboBox = ref()

    return {
      firstname,
      email,
      mobile,
      password,
      checkbox,
      comboBox,
    }
  },
}
</script>
