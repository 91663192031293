<template>
  <v-form>
    <v-row>
      <v-col cols="12" md="3">
        <label for="firstnameHorizontalIcons">First Name</label>
      </v-col>

      <v-col cols="12" md="9">
        <v-text-field
          id="firstnameHorizontalIcons"
          v-model="firstname"
          :prepend-inner-icon="icons.mdiAccountOutline"
          dense
          hide-details
          outlined
          placeholder="First Name"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <label for="emailHorizontalIcons">Email</label>
      </v-col>

      <v-col cols="12" md="9">
        <v-text-field
          id="emailHorizontalIcons"
          v-model="email"
          :prepend-inner-icon="icons.mdiEmailOutline"
          dense
          hide-details
          outlined
          placeholder="Email"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <label for="mobileHorizontalIcons">Mobile</label>
      </v-col>

      <v-col cols="12" md="9">
        <v-text-field
          id="mobileHorizontalIcons"
          v-model="mobile"
          :prepend-inner-icon="icons.mdiCellphone"
          dense
          hide-details
          outlined
          placeholder="Number"
          type="number"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <label for="passwordHorizontalIcons">Password</label>
      </v-col>

      <v-col cols="12" md="9">
        <v-text-field
          id="passwordHorizontalIcons"
          v-model="password"
          :prepend-inner-icon="icons.mdiLockOutline"
          dense
          hide-details
          outlined
          placeholder="Password"
          type="password"
        ></v-text-field>
      </v-col>

      <v-col cols="12" offset-md="3">
        <v-checkbox v-model="checkbox" class="mt-0" hide-details label="Remember me"></v-checkbox>
      </v-col>

      <v-col cols="12" offset-md="3">
        <v-btn color="primary">
          Submit
        </v-btn>
        <v-btn class="mx-2" outlined type="reset">
          Reset
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCellphone, mdiEmailOutline, mdiLockOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const firstname = ref('')
    const email = ref('')
    const mobile = ref()
    const password = ref()
    const checkbox = ref(false)

    return {
      firstname,
      email,
      mobile,
      password,
      checkbox,

      // icons
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCellphone,
        mdiLockOutline,
      },
    }
  },
}
</script>
