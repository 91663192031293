<template>
  <v-form class="multi-col-validation">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="firstName"
          dense
          hide-details
          label="First Name"
          outlined
          placeholder="First Name"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="lastName"
          dense
          hide-details
          label="Last Name"
          outlined
          placeholder="Last Name"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field v-model="email" dense hide-details label="Email" outlined placeholder="Email"></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field v-model="city" dense hide-details label="City" outlined placeholder="City"></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="country"
          dense
          hide-details
          label="Country"
          outlined
          placeholder="Country"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="company"
          dense
          hide-details
          label="Company"
          outlined
          placeholder="Company"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-checkbox v-model="checkbox" class="mt-0" hide-details label="Remember me"></v-checkbox>
      </v-col>

      <v-col cols="12">
        <v-btn color="primary">
          Submit
        </v-btn>
        <v-btn class="mx-2" outlined type="reset">
          Reset
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const firstName = ref('')
    const lastName = ref('')
    const city = ref('')
    const country = ref('')
    const company = ref('')
    const email = ref('')
    const checkbox = ref(false)

    return {
      firstName,
      lastName,
      city,
      country,
      company,
      email,
      checkbox,
    }
  },
}
</script>
